<template>
  <div class="total-card __color" v-if="bag">
    <div class="total-card-content" :style="`--color:${card.color ? card.color : '#ffcfcf'};--picture:url(${bag.picture})`">
      <div class="total-card-left">
        <div class="total-card-icon" :style="`--iconSize:${card.iconSize || '200%'}`">
          <figure class="figure">
            <iconic v-if="!bag.picture" :name="bag.icon || 'lock1a'" />
          </figure>
        </div>
        <div class="total-card-info">
          <div class="total-card-info-content">
            <div class="total-card-info-label">
              <span>{{ $locale.words["credit-bag"] + " " + Go.number_format(bag.obtain) }}</span>
              <div class="flex-middle" v-if="bag.obtain !== bag.obtainPlus">
                <span class="info-plus">
                  <span class="obtain">{{ bag.obtain }}</span>
                  <span class="obtainPlus">{{ bag.obtainPlus }}</span>
                </span>
                <span class="card-badge">
                  <BadgeDiscount :num="bag.obtainPlus" :total="bag.obtain" />
                </span>
              </div>
              <span class="info-plus" v-else>{{ bag.obtain }}</span>
            </div>
            <div class="total-card-info-desc">{{ card.desc }}</div>
            <ProgressBar v-if="card.progress" height="8px" :color="card.color || '#ffcfcf'" :percent="card.progress" />
          </div>
        </div>
      </div>
      <div class="total-card-right">
        <div>
          <span class="sign">{{ card.sign }}</span>
          <span class="mount">{{ card.num }}</span>
        </div>
        <div>
          <span class="mount_label">{{ card.numLabel }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["card", "bag"],
  components: {
    ProgressBar: () => import("../widgets/ProgressBar.vue"),
    BadgeDiscount: () => import("../widgets/BadgeDiscount.vue"),
  },
};
</script>
